@import "../../assets/style/variables.module.scss";

.sideMenu {
  position: absolute;
  left: 15vh;
  bottom: 0;
  width: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform-origin: bottom left;
  transform: rotate(270deg);
  z-index: 200;

  .wrapper {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 3vh;
    margin: 0.5vh 0;

    button {
      font-size: 1.97vh;
      letter-spacing: 0.47em;
      font-family: $--proxima-regular;
      text-transform: uppercase;
      margin-right: 0.6em;
      cursor: pointer;
      background: transparent;

      &::after {
        content: "●";
        display: inline-block;
        margin-left: 0.6em;
      }

      &:last-child {
        margin-left: 0;

        &::after {
          content: "";
          margin-left: 0;
        }
      }

      &:hover {
        color: $--main-blue;
      }
    }

    .selected {
      color: $--main-blue;
    }
  }
}
