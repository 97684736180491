@import '../../assets/style/variables.module.scss';

.workWrapper {
  margin-top: 80px;
}

.navBar {
  width: 90vw;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%);
  z-index: 200;
  position: absolute;

  button {
    width: 192px;
    position: relative;
    div {
      width: calc(100% - 30px);
      -webkit-transition: width 1s;
      transition: width 1s;
      border-top: 1px solid #333;
      position: absolute;
      right: 21px;
      padding-top: 4px;
      top: 7px;
      line-height: 21px;
      height: 21px;
      overflow: hidden;
    }

    svg {
      position: absolute;
      left: 0;
      top: 0;
      width: 30px;
      height: 15px;
      color: rgb(45, 50, 53);
    }

    &:last-child {
      svg {
        right: 0;
        left: unset;
      }
    }
  }
}

.keyboardAlert {
  top: calc(50% - -50px);
  left: 10vw;
  width: 220px;
  min-width: 80px;
  z-index: 100;
  position: absolute;
  transition: all 0.7s ease;
}

.prev {
  position: absolute;
  top: 50%;
  left: 10vw;
  width: 220px;
  min-width: 80px;
  -webkit-transition: width 1s;
  transition: width 1s;
  height: 42px;
  border: none;
  border-radius: 0;
  background: none;
  cursor: pointer;
  color: rgb(45, 50, 53);
  font-family: 'Bell MT';
  font-style: italic;
  font-size: 18px;
  letter-spacing: 0.15em;
  z-index: 100;

  &::before {
    content: 'previous project';
    width: calc(100% - 30px);
    -webkit-transition: width 1s;
    transition: width 1s;
    border-top: 1px solid #333;
    position: absolute;
    right: 21px;
    padding-top: 4px;
    top: 7px;
    line-height: 21px;
    height: 21px;
    overflow: hidden;
  }

  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 15px;
    color: rgb(45, 50, 53);
  }
}
.next {
  right: 10vw;
  position: absolute;
  top: 50%;
  width: 220px;
  min-width: 80px;
  -webkit-transition: width 1s;
  transition: width 1s;
  height: 42px;
  border: none;
  border-radius: 0;
  background: none;
  cursor: pointer;
  color: rgb(45, 50, 53);
  font-family: 'Bell MT';
  font-style: italic;
  font-size: 18px;
  letter-spacing: 0.15em;
  z-index: 100;

  &::before {
    content: 'next project';
    width: calc(100% - 30px);
    -webkit-transition: width 1s;
    transition: width 1s;
    border-top: 1px solid #333;
    position: absolute;
    right: 21px;
    padding-top: 4px;
    top: 7px;
    line-height: 21px;
    height: 21px;
    overflow: hidden;
  }
  svg {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 15px;
    color: rgb(45, 50, 53);
  }
}
